.App {
  text-align: center;
}

.App-header {
  z-index: 0;
  position: relative;
  width: 100%;
}

.Header {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Logo {
  position: absolute;
  right: 5%;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

html, body {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.backgroundDecoration {
  position: fixed;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  z-index: -1;
  filter: blur(65px);
}

main {
  flex: 1;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

h1 {
  font-size: 26px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding: 1rem;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

@media (max-width: 900px) {
  h1 {
    font-size: 6vw;
    white-space: normal;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 5vw;
    white-space: normal;
  }
}

p, span, div {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
